<template>
  <b-card>
    <h4>Meus Afiliados: {{ total }}</h4>
    <TablePagination
      v-model="items"
      url="/user/affiliate/my_affiliates/paginate"
      :per-page="10"
      :fields="fields"
      @total="total = $event"
    >
      <template v-slot:name="data">
        <span>{{ data.items.name }}</span>
      </template>

      <template v-slot:created_at="data">
        <span v-if="data.items.created_at !== null">{{ data.items.created_at | date }}</span>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BTable } from 'bootstrap-vue'
import moment from 'moment'
import TableMixin from './mixins/TableMixin'
import TablePagination from '@/components/TablePagination/Index.vue'

export default {
  components: {
    BCard,
    TablePagination,
    BTable
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    }
  },
  mixins: [TableMixin],
  data: () => ({
    total: 0,
    items: [],
    fields: [
      {
        key: 'name',
        label: 'nome'
      },
      {
        key: 'created_at',
        label: 'Data de Indicação'
      }
    ]
  })
}
</script>
