<template>
  <b-card>
    Total de Saques: {{ total_withdraw | currency }}
    <TablePagination
      v-model="items"
      url="/user/affiliate/withdraw_history/paginate"
      :per-page="10"
      :fields="fields"
      @raw="total_withdraw = $event.total_withdraw_of_user"
    >
      <template v-slot:amount="data">
        <span>{{ data.items.amount | currency }}</span>
      </template>

      <template v-slot:status="data">
        <b-button
          size="sm"
          :variant="getStatus(data.items.status).color"
        >
          {{ getStatus(data.items.status).message }}
        </b-button>
      </template>

      <template v-slot:created_at="data">
        <span v-if="data.items.created_at !== null">{{ data.items.created_at | date }}</span>
      </template>
    </TablePagination>
  </b-card>
</template>

<script>
import { BCard, BTable, BButton } from 'bootstrap-vue'
import moment from 'moment'
import TableMixin from './mixins/TableMixin'
import TablePagination from '@/components/TablePagination/Index.vue'
import currencyPrecision from '@/libs/currencyPrecision'

export default {
  components: {
    BCard,
    TablePagination,
    BTable,
    BButton
  },
  filters: {
    date (val) {
      return moment(val).format('DD/MM/Y HH:mm:ss')
    },
    currency (val) {
      return currencyPrecision(val, 2, 2)
    }
  },
  mixins: [TableMixin],
  data: () => ({
    total_withdraw: 0,
    items: [],
    fields: [
      {
        key: 'amount',
        label: 'Valor'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'created_at',
        label: 'Data de Pedido'
      }
    ]
  }),
  methods: {
    getStatus (status) {
      const response = {
        message: '',
        color: ''
      }

      switch (status) {
      case 1:
        response.message = 'Pendente'
        response.color = 'relief-primary'
        break
      case 2:
        response.message = 'Análise'
        response.color = 'relief-warning'
        break
      case 3:
        response.message = 'Recusado'
        response.color = 'relief-danger'
        break
      case 4:
        response.message = 'Aprovado'
        response.color = 'relief-success'
        break

      default:
        response.message = 'Não definido'
        response.color = 'secondary'
        break
      }

      return response
    }
  }
}
</script>
