<template>
  <div>
    <b-modal
      v-model="visible"
      cancel-variant="outline-secondary"
      centered
      hide-footer
      title="Escolha como quer receber a sua comissão"
      @change="(val) => $emit('update:visible', val)"
    >
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-center mb-1"
        >
          <b-button
            variant="relief-primary"
            @click.stop="setType(1)"
          >
            Saldo em conta
          </b-button>
        </b-col>

        <b-col
          cols="12"
          class="d-flex justify-content-center"
        >
          <b-button
            variant="relief-primary"
            @click.stop="setType(2)"
          >
            PIX
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
      v-model="withdrawPixDialog"
      cancel-variant="outline-secondary"
      centered
      hide-footer
    >
      <b-form-group
        label-size="lg"
        label-align="center"
        label="Nome do remetente"
        label-for="defaultLabel"
      >
        <b-form-input
          id="defaultLabel"
          v-model="pix_sender"
          class="w-100 text-center"
          placeholder="Remetente"
        />
      </b-form-group>

      <b-form-group
        label-size="lg"
        label-align="center"
        label="Informe sua chave PIX"
        label-for="defaultLabel"
      >
        <b-form-input
          id="defaultLabel"
          v-model="pix_address"
          class="w-100 text-center"
          placeholder="Chave PIX"
        />
      </b-form-group>

      <b-button
        class="w-100"
        variant="relief-primary"
        :disabled="!pix_sender || !pix_address"
        @click.stop="pixConfirm()"
      >
        Continuar
      </b-button>
    </b-modal>

    <b-modal
      v-model="withdrawDialogConfirm"
      cancel-variant="outline-secondary"
      centered
      hide-header
      hide-footer
      no-close-on-backdrop
      title="Você tem certeza que deseja continuar?"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2 text-center"
        >
          <span>Se clicar em sim, seu pedido entrará em análise e podera ser processado em até 48 horas.</span>
        </b-col>

        <b-col
          cols="12"
          class="mb-2 text-center"
        >
          <h3>Atenção:</h3>
          <span>Não será possível cancelar ou alterar o valor do seu pedido.</span>
        </b-col>

        <b-col
          v-if="type === 2"
          cols="12"
          class="mb-2 text-center"
        >
          <h4 class="text-danger font-weight-bolder">
            Confirme os dados da transação abaixo:
          </h4>

          <span class="text-danger font-weight-bolder">Remetente: </span> <span>{{ pix_sender }}</span> <br>
          <span class="text-danger font-weight-bolder">Chave PIX: </span> <span>{{ pix_address }}</span>

        </b-col>

        <b-col
          cols="12"
          class="text-center"
        >
          <h4>Deseja continuar?</h4>
        </b-col>

        <b-col
          cols="12"
          class="mt-1"
        >
          <b-button
            class="w-100 mb-1"
            variant="relief-success"
            :disabled="loading"
            @click.stop="withdrawConfirm()"
          >
            <span v-if="!loading">Sim</span>
            <b-spinner
              v-else
              small
            />
          </b-button>

          <b-button
            class="w-100"
            variant="relief-danger"
            :disabled="loading"
            @click.stop="withdrawCancel()"
          >
            <span v-if="!loading">Não</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton
  },
  model: {
    prop: 'visible',
    event: 'update:visible'
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  data: () => ({
    loading: false,
    type: undefined,
    pix_sender: '',
    pix_address: '',
    withdrawPixDialog: false,
    withdrawDialogConfirm: false
  }),
  watch: {
    type (val) {
      if (val === 1) {
        this.withdrawDialogConfirm = true
      }

      if (val === 2) {
        this.withdrawPixDialog = true
      }
    }
  },
  methods: {
    setType (type) {
      this.type = type
      this.$emit('update:visible', false)
    },
    pixConfirm () {
      this.withdrawPixDialog = false
      this.withdrawDialogConfirm = true
    },
    async withdrawConfirm () {
      this.loading = true
      const url = '/user/affiliate/commission_withdraw'
      const params = {
        type: this.type,
        pix_sender: this.pix_sender,
        pix_address: this.pix_address
      }

      await this.$http.post(url, params).then(() => {
        this.$bvToast.toast('Solicitação realizada com sucesso!', {
          title: 'Sucesso',
          variant: 'success',
          solid: true
        })
        this.withdrawCancel()

        window.location.reload()
      }).catch(() => {
        this.$bvToast.toast('Ops! Algo de errado não está certo!', {
          title: 'Erro',
          variant: 'danger',
          solid: true
        })
      })
        .finally(() => {
          this.loading = false
        })
    },
    withdrawCancel () {
      this.withdrawPixDialog = false
      this.withdrawDialogConfirm = false
      this.type = undefined
      this.pixel_address = ''
      this.pixel_sender = ''
    }
  }
}
</script>
