<template>
  <b-form-group
    label-size="lg"
    label-align="center"
    label="Seu link de referência é:"
    label-for="defaultLabel"
  >
    <div class="d-flex justify-content-center">
      <b-form-input
        id="defaultLabel"
        v-model="refLink"
        class="w-50 text-center"
        placeholder="Normal Input"
        :readonly="true"
      />

      <b-button
        v-clipboard:copy="refLink"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        class="ml-1"
        variant="relief-primary"
      >
        <feather-icon icon="CopyIcon" />
      </b-button>
    </div>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    ToastificationContent
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
    refLink: 'https://app.contasinfinity.com.br/#/register?ref='
  }),
  mounted () {
    this.refLink += this.userData.id
  },
  methods: {
    onCopy () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Link de referência copiado com sucesso!',
          icon: 'BellIcon',
          variant: 'success'
        }
      })
    },
    onError () {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Falha ao copiar!',
          icon: 'BellIcon',
          variant: 'danger'
        }
      })
    }
  }
}
</script>
