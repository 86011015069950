<template>
  <b-row>
    <b-col
      cols="12"
      class="mt-1 d-flex justify-content-center"
    >
      <h3 class="font-weight-bolder text-success">
        <span>Saldo disponível: </span>
        <span v-if="!loading">{{ balance.available | currency }}</span>
        <span v-else>
          <b-spinner small />
        </span>
      </h3>
    </b-col>

    <b-col
      cols="12"
      class="mt-1 d-flex justify-content-center"
    >
      <h4>
        <span>Saldo a liberar: </span>
        <span v-if="!loading">{{ balance.pending | currency }}</span>
        <span v-else>
          <b-spinner small />
        </span>
      </h4>
    </b-col>

    <b-col
      cols="12"
      class="d-flex justify-content-center mt-2"
    >
      <b-button
        variant="relief-primary"
        :disabled="!balance.withdraw_available"
        @click.stop="withdraw()"
      >
        Sacar comissão
      </b-button>

      <CommissionWithdraw v-model="withdrawDialog" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BSpinner
} from 'bootstrap-vue'
import currencyPrecision from '@/libs/currencyPrecision'
import CommissionWithdraw from './CommissionWithdraw.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    CommissionWithdraw
  },
  filters: {
    currency (val) {
      return currencyPrecision(val, 2, 2)
    }
  },
  data: () => ({
    loading: true,
    withdrawDialog: false,
    balance: {
      available: 0,
      pending: 0,
      withdraw_available: false
    }
  }),
  async mounted () {
    await this.getBalance()
  },
  methods: {
    async getBalance () {
      this.loading = true
      const url = '/user/affiliate/balance'

      await this.$http.get(url).then(res => {
        this.balance.available = res.data.available
        this.balance.pending = res.data.pending
        this.balance.withdraw_available = res.data.withdraw_available
      }).finally(() => {
        this.loading = false
      })
    },
    async withdraw () {
      this.withdrawDialog = true
      // const url = '/user/affiliate/commission_withdraw'

      // await this.$http.post(url).then(res => {
      //   this.$bvToast.toast(res.data.message, {
      //     title: 'Sucesso',
      //     variant: 'success',
      //     solid: true
      //   })
      // }).catch(err => {
      //   this.$bvToast.toast(err.response.data.message, {
      //     title: 'Erro',
      //     variant: 'danger',
      //     solid: true
      //   })
      // })
    }
  }
}
</script>
