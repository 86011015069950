<template>
  <section>
    <b-card>
      <b-card-title> Informações </b-card-title>

      <b-card-text>
        <b-row>
          <b-col cols="12">
            <CopyRef />
          </b-col>

          <b-col
            cols="12"
            class="text-center mt-2"
          >
            <p>Indique e ganhe 5% de todo saldo gasto pelo seu indicado.</p>
            <p>Obs¹ : A comissão do indicador será liberada como saldo de comissão a partir de 10 dias úteis da compra de seu indicado.</p>
            <p>Obs² : Saldos / compras que por algum motivo tenham sido reembolsados terão o bônus descontado.</p>
            <p>Obs³ : Você não pode indicar a si mesmo.</p>
            <p>Ao compartilhar o link de indicações você declara que leu as regras e que concorda com os termos e nossa politica de uso.</p>
          </b-col>

          <b-col
            cols="12"
            class="mt-2 d-flex justify-content-center"
          >
            <h4>Mínimo para saque: R$ 10</h4>
          </b-col>
        </b-row>

        <hr>

        <Balance />
      </b-card-text>
    </b-card>

    <Navigation />
  </section>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
import CopyRef from './CopyRef.vue'
import Balance from './Balance.vue'
import Navigation from './Navigation.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BButton,
    CopyRef,
    Balance,
    Navigation
  }
}
</script>
