<template>
  <b-tabs
    content-class="mt-1"
    align="center"
  >
    <b-tab title="Indicados">
      <b-card>
        <MyAffiliates />
      </b-card>
    </b-tab>

    <b-tab title="Histórico de Saques">
      <b-card>
        <WithdrawHistory />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'
import MyAffiliates from './MyAffiliates.vue'
import WithdrawHistory from './WithdrawHistory.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    MyAffiliates,
    WithdrawHistory
  }
}
</script>
